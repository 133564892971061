import React from "react"
import { graphql } from "gatsby"
import Img from "gatsby-image"
import Layout from "../components/layout/layout"
import Grid from "../components/grid/grid"
import "./lost-in-panslation.less"

const LostInPanslationPage = ({ data }) => {
  const title = "Lost in Panslation"

  const {
    allContentfulLostInPanslation: { edges: issues },
  } = data

  return (
    <Layout title={title} className="lost-in-panslation">
      <div>
        <h1 className="lost-in-panslation__header">
          Lost in Panslation Newsletter
        </h1>
      </div>
      <section
        className="lost-in-panslation__hero"
        style={{ maxWidth: 1024, margin: "0 auto" }}
      >
        <div className="lost-in-panslation__hero__image">
          <Img fluid={data.file.childImageSharp.fluid} />
        </div>
        <div className="lost-in-panslation__hero__text">
          <h2>What does it all mean?</h2>
          <p>
            This series started as a little lockdown project. Each issue I try
            to tackle a different dish, sometimes more than one. Different
            people have generously contributed recipes, either as links from
            websites, pages from cookbooks or even notes from their grandma.
          </p>
          <p>
            Hopefully this newsletter allows you to do a little armchair travel
            as I navigate through different cuisines.
          </p>
          <a
            href="https://kitchenpan.substack.com/"
            className="btn btn--ghost btn--round btn--large"
          >
            Subscribe
          </a>
        </div>
      </section>
      <section
        className="lost-in-panslation__contributions"
        style={{ maxWidth: 1024, margin: "0 auto" }}
      >
        <h2>How can you get involved?</h2>
        <p>
          This series would not be possible without all the lovely humans taking
          the time to share recipes in other languages. Even voice notes and
          scribbles on a napkin qualify, so please do keep submissions coming
          and slide into my{" "}
          <a className="link" href="https://www.instagram.com/kitchen__pan/">
            Instagram
          </a>{" "}
          DMs! The possibilities are endless. I look forward to getting
          thoroughly Lost in Panslation with you.
        </p>
      </section>
      <div className="lost-in-panslation__issues">
        <section
          className="lost-in-panslation__issues__text"
          style={{ maxWidth: 1024, margin: "0 auto" }}
        >
          <h3>Past issues</h3>
        </section>

        <Grid items={issues.map(issue => issue.node)}>
          {issue => {
            return (
              <a key={issue.id} href={issue.link}>
                <div className="lost-in-panslation__issue">
                  <Img fluid={issue.featuredImage.fluid} />
                  <div className="lost-in-panslation__issue__title">
                    <h3>{issue.title}</h3>
                  </div>
                </div>
              </a>
            )
          }}
        </Grid>
      </div>
    </Layout>
  )
}

export const query = graphql`
  query {
    file(relativePath: { eq: "lost-in-panslation.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 344, quality: 100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    allContentfulLostInPanslation(sort: { fields: publishDate, order: DESC }) {
      edges {
        node {
          id
          publishDate(formatString: "MMMM DD, YYYY")
          rawDate: publishDate
          title
          link
          featuredImage {
            file {
              url
            }
            fluid(maxWidth: 720) {
              ...GatsbyContentfulFluid
            }
          }
        }
      }
    }
  }
`

export default LostInPanslationPage
